// Generated by Framer (9f68555)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {hLSJ0zgdY: {hover: true}};

const serializationHash = "framer-vZlOP"

const variantClassNames = {hLSJ0zgdY: "framer-v-f537y7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "hLSJ0zgdY", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-f537y7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hLSJ0zgdY"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"hLSJ0zgdY-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1toyw4h"} data-framer-name={"rows"} layoutDependency={layoutDependency} layoutId={"AWiaDDdXc"} style={{backgroundColor: "var(--token-9af43f3c-439d-4ff9-8264-9b3ba25e1d2b, rgb(35, 44, 54))"}}><ComponentViewportProvider ><motion.div className={"framer-11bfhks-container"} layoutDependency={layoutDependency} layoutId={"gqT33TteB-container"}><Material color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"East"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"gqT33TteB"} layoutId={"gqT33TteB"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-xxv39p-container"} layoutDependency={layoutDependency} layoutId={"YwEyhze_8-container"}><Material color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"East"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"YwEyhze_8"} layoutId={"YwEyhze_8"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vZlOP.framer-3o5yct, .framer-vZlOP .framer-3o5yct { display: block; }", ".framer-vZlOP.framer-f537y7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 4px; position: relative; width: min-content; }", ".framer-vZlOP .framer-1toyw4h { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 18px; }", ".framer-vZlOP .framer-11bfhks-container, .framer-vZlOP .framer-xxv39p-container { flex: none; height: 18px; position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vZlOP.framer-f537y7, .framer-vZlOP .framer-1toyw4h { gap: 0px; } .framer-vZlOP.framer-f537y7 > *, .framer-vZlOP .framer-1toyw4h > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-vZlOP.framer-f537y7 > :first-child, .framer-vZlOP .framer-1toyw4h > :first-child { margin-left: 0px; } .framer-vZlOP.framer-f537y7 > :last-child, .framer-vZlOP .framer-1toyw4h > :last-child { margin-right: 0px; } }", ".framer-vZlOP.framer-v-f537y7.hover .framer-1toyw4h { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Etobni05f":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertUFy6KMzz: React.ComponentType<Props> = withCSS(Component, css, "framer-vZlOP") as typeof Component;
export default FramertUFy6KMzz;

FramertUFy6KMzz.displayName = "arrow interaction";

FramertUFy6KMzz.defaultProps = {height: 26, width: 26};

addFonts(FramertUFy6KMzz, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})